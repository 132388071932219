<template>
  <div class="no__chat_selected" v-if="currentChat.number === null" id="el">
    <img
        class="no__chat_selected_img"
        src="@/assets/images/intro-connection-light.jpg"
        alt=""
    />
    <div class="no__chat_selected_text">
      <h3>Seleccionar un número para empezar una conversación</h3>
    </div>
  </div>
  <div class="chat" v-else>
    <ContextMenu :display="showContextMenu" ref="menu">
      <div @click.stop="deleteMessage()">Borrar mensaje</div>
    </ContextMenu>
    <div class="chat__header">
<!-- <v-avatar>CV</v-avatar>-->
      <div class="chat__header_info">
        <h3>{{ currentChat.name }}</h3>
        <p>{{ currentChat.number }}</p>
      </div>
      <div class="chat__header_right">
        <v-btn icon>
          <v-icon  :color="showSelect ? '#4c8ff3' : ''" @click="setShowSelect" title="Guardar adjuntos al encargo">mdi-ballot</v-icon>
        </v-btn>
        <v-btn icon :disabled="currentChat.id_encargo === null">
          <v-icon @click="sendTemplate(0)"  title="Mandar plantilla">mdi-application-export</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="addNumber" title="Editar contacto">mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="markNumberUnread" title="Marcar como no leído">mdi-email-alert</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon v-if="!currentChat.followed"  @click="addFollow"  title="Marcar como favorito">mdi-star-box</v-icon>
          <v-icon v-else color="#ff0000" @click="removeFollow" title="Quitar favorito">mdi-star-box</v-icon>
        </v-btn>
<!--        <v-btn icon><v-icon color="#ff0000">mdi-star-box</v-icon></v-btn>-->
<!--        <v-btn icon><v-icon>mdi-dots-vertical</v-icon></v-btn>-->
      </div>
    </div>
    <div class="chat__body" id="chat__body" ref="container">
      <div
          class="first__time_chat"
          v-if="currentChat.chatMessages.length === 0"
      >
        <p>
          No existe ninguna conversación con
          {{ currentChat.name }}.<br />
          Inicia una conversación enviando un mensaje.
        </p>
      </div>
        <div class="not__registered" v-if="currentChat.not_registered">ESTE USUARIO NO TIENE WHATSAPP</div>
        <div v-if="currentChat.totalOfMessages > currentChat.chatMessages.length" class="chat__row chat__center">
          <span class="chat__showAllMessages" @click="showAllMessages"> Cargar mensajes anteriores </span>
        </div>
        <div class="chat__row" v-for="(message, index) in currentChat.chatMessages"
          :key="index">
          <input v-if="showSelect" class="chat__select" type="checkbox" :id="message.id" :name="message.id" :value="message.id" v-model="sendSelected" />
          <div :class="
              message.send_from === phoneList.current.number
                ? 'chat__sender chat__message'
                : 'chat__reciever chat__message'
            "
          >
          <div class="chat__message__group" v-if="message.deleted !== 1">
              <div>
                <span v-if="message.replied" class="chat__replied">{{message.replied}}</span>
                <span v-if="message.replied_data" class="chat__replied">
                  <span v-if="message.replied_type && message.replied_type.includes('image')" class="chat__image chat__download" @click="downloadAttachReply(index)">
                    <img :src="'data:'+ message.replied_type +';base64, '+ message.replied_data" :alt="message.replied_name" /><br />
                  </span>
                  <span v-else-if="message.replied_data" @click="downloadAttachReply(index)" class="chat__download">
                      <v-icon color="#0D47A1" style="font-size: 30px;">mdi-file-download</v-icon>
                      {{ message.replied_name || '' }}
                  </span>
                </span>

                <span v-if="message.media_type && message.media_type.includes('image')" class="chat__image chat__download" @click="downloadAttach(index)">
                  <img :src="'data:'+ message.media_type +';base64, '+ message.media_data" :alt="message.media_name" /><br />
                </span>
                <span v-else-if="message.media_data" @click="downloadAttach(index)" class="chat__download">
                    <v-icon color="#0D47A1" style="font-size: 30px;">mdi-file-download</v-icon>
                    {{ message.media_name || '' }}
                </span>
                <template v-if="message.message !== message.media_name">{{ message.message }}</template>
                <span v-if="message.message_date" class="chat__timestamp">{{ message.message_date | showDate }}</span>
                <span v-else><v-icon x-small style="color: red; margin-left: 4px;">mdi-cancel</v-icon></span>
            </div>
            <div class="mouse_option">
              <v-icon class="hide" @click.stop="numberOptions($event, message.id)">mdi-chevron-down</v-icon>
            </div>
          </div>
          <div v-else style="color: #525252; font-size: 12px; font-style: italic;"><v-icon x-small style="margin-right: 4px;">mdi-cancel</v-icon>Se eliminó este mensaje</div>
        </div>
      </div>
    </div>
    <div v-if="!showSelect" class="chat__footer">
      <v-btn icon :disabled="phoneList.current.meta === 1" @click="attachLocalFile"  ><v-icon>mdi-attachment</v-icon></v-btn>
      <div class="messages">
        <div v-if="message === '' || message === '\n'" class="placeholder">Escribe un mensaje aquí</div>
        <contenteditable tag="div" :contenteditable="isEditable" v-model="message" :noNL="false" :noHTML="true" :isEditable="isEditable"  @keypress="keyPress" />
        <v-btn icon @click="sendMessage"><v-icon>mdi-send</v-icon></v-btn>
      </div>
    </div>
    <div v-else class="chat__messages_save">
      <div>
        <span class="clear__selected" @click="setShowSelect">&#10005;</span>{{sendSelected.length}} seleccionado<span v-if="sendSelected.length !== 1" >s</span>
      </div>
      <div>Encargo:
        <input type="text" placeholder="Encargo guardar adjuntos" v-model="idEncargo" />
      </div>
      <div>
        <v-btn icon @click="saveAttach"><v-icon>mdi-send</v-icon></v-btn>
      </div>
    </div>
    <router-view :key="$route.fullPath" name="upload" @receiveFileAttached="receiveFileAttached($event)"></router-view>
    <router-view :key="$route.fullPath" name="send_template"></router-view>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import plataformaService from '@/services/plataforma'
import download from 'downloadjs'
import ContextMenu from "@/components/ContextMenu"
import phoneList from "@/store/module/phoneList";
import vCard from 'vcard-parser'

export default {
   name: "Chat",
  destroyed: function () {
    this.$socket.disconnect()
  },
  components: {
    ContextMenu,
  },
  computed: {
    ...mapState(['login', 'chats', 'currentChat', 'phoneList']),
  },
  data: () => ({
    followed: true,
    idEncargo: "",
    isEditable: true,
    message: "",
    messageTarget: 0,
    search: "",
    sendSelected: [''],
    showSelect: false,
    showContextMenu: false,
  }),
  filters: {
    showDate(value) {
      if (!value) {
        return ""
      }
      let regex = value.substr(0, 10).match(/^\s*([0-9]+)\s*-\s*([0-9]+)\s*-\s*([0-9]+)$/);
      return regex[3] + "/" + regex[2] + "/" + regex[1] + ' ' + value.substr(11, 5);
    },
    parseVCard(value) {
      let vCardObj = vCard.parse(value)
      let datos = [];
      datos.push(vCardObj.fn[0].value || "")
      datos.push(vCardObj.email[0].value || "")
      datos.push(vCardObj.tel[0].value || "")
      datos.push(vCardObj.adr[0].value.filter(function (el) {
        return el !== ""
      }).join("\n"))
      return datos.join("\n")
    },
  },
  methods: {
    ...mapMutations([
      'UPDATE_CHAT', 'SET_TOKEN', 'MARK_NOT_FOLLOWED', 'MARK_FOLLOWED'
    ]),
    ...mapActions([
      'setToken',
      'clientUpdate',
      'getChatList',
      'markUnread',
      'getMessagesFromNumber',
    ]),
    addNumber() {
      this.$router.push({
        name: "Number",
        params: {id_encargo: this.currentChat.id_encargo, name: this.currentChat.name, number: this.currentChat.number}
      });
    },
    sendTemplate(auto) {
      this.$router.push({
        name: "SendTemplate",
        params: { auto: auto }
      })
    },
    async downloadAttach(index) {
      let response = await plataformaService.downloadFile(this.currentChat.chatMessages[index]['id'])
      download(response.data, this.currentChat.chatMessages[index]['media_name'], this.currentChat.chatMessages[index]['media_type']);
    },
    async downloadAttachReply(index) {
      let response = await plataformaService.downloadFile(this.currentChat.chatMessages[index]['replied_id'])
      download(response.data, this.currentChat.chatMessages[index]['replied_name'], this.currentChat.chatMessages[index]['replied_type']);
    },
    attachLocalFile() {
      this.$router.push({
        name: "UploadFile",
      });
    },
    addFollow() {
      let follow = []
      if (localStorage.getItem('follow') !== null) {
        follow = JSON.parse(localStorage.getItem('follow'))
      }
      localStorage.setItem('follow', JSON.stringify([...follow, this.currentChat.number]))
      this.MARK_FOLLOWED()
    },
    deleteMessage() {
      let messageToDelete = this.currentChat.chatMessages.filter(message => {
        return message.id === this.messageTarget
      })
      let messageData = { message: messageToDelete[0].message, send_to: this.currentChat.number, send_from: this.phoneList.current.number, id: messageToDelete[0].id, id_tecnico: this.login.id_tecnico, deleted: 1 }
      this.$socket.emit('send_delete_message', messageData )
      this.$refs.menu.close()
      this.UPDATE_CHAT( messageData )
    },
    keyPress(ev){
      if (!ev.shiftKey && ev.keyCode === 13) {
        this.sendMessage()
        ev.preventDefault()
      }
    },
    markNumberUnread() {
      this.markUnread({ client: this.currentChat.number, whatsapp: this.phoneList.current.number });
      this.$socket.emit('mark_unread', {client: this.currentChat.number, whatsapp: this.phoneList.current.number})
    },
    numberOptions(e, id) {
      this.messageTarget = id
      this.$refs.menu.open(e)
    },
    removeFollow() {
      let follow = JSON.parse(localStorage.getItem('follow'))
      let filtered = follow.filter(number => number !== this.currentChat.number)
      if (filtered.length > 0) {
        localStorage.setItem('follow', JSON.stringify([...filtered]))
      } else {
        localStorage.setItem('follow', JSON.stringify([]))
      }
      this.MARK_NOT_FOLLOWED()
    },
    async receiveFileAttached(data) {
      let response = await plataformaService.insertFile({
        name: data.name,
        type: data.type,
        data: data.data,
        send_to: this.currentChat.number,
        send_from: this.phoneList.current.number,
        id_tecnico: this.login.id_tecnico
      })
      const messageId = response.data.id;
      let date = new Date()
      let messageData = {
        id: messageId,
        body: this.message,
        multimedia: true,
        send_from: this.phoneList.current.number,
        send_to: this.currentChat.number,
        message_date: date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0') + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
      }
      this.$socket.emit('send_message', messageData)
      this.setToken(response.data.token)
      this.UPDATE_CHAT({...messageData, media_name: data.name, media_type: data.type, media_data: data.data, deleted: 0, add: true})
      this.message = "";
    },
    setShowSelect() {
      if (this.showSelect) {
        this.sendSelected = [];
      }
      this.showSelect = !this.showSelect
    },
    scrollToEnd() {
      const content = this.$refs.container;
      content.scrollTop = content.scrollHeight;
    },
    async sendMessage() {
      //this.$cookies.set("token0","eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzE4MDEwNTcsImV4cCI6MTYzMTgwNDY1NywiZGF0YSI6eyJpZCI6MCwiYXJlYSI6IkNvbnRyb2wifX0.CUx6W_mIa_OkV3ZSFuBAp8Rbv4Ut2llDjXN_hZWMngs");
      //const authToken = this.$cookies.get("token0");
      this.message = this.message.replace(/\n+$/, "")
      if (this.message === null || this.message === " ") {
        return;
      }
      let date = new Date()
      let showModalPredefinedMessages = false
      if (this.phoneList.current.meta > 0) {
        if (this.currentChat.allowedAt) {
          let date2 = new Date(this.currentChat.allowedAt)
          date2.setDate(date2.getDate() + 1)
          if (date > date2) {
            showModalPredefinedMessages = true
          }
        } else {
          showModalPredefinedMessages = true
        }
      }

      if (showModalPredefinedMessages) {
        this.sendTemplate(1)
        this.message = "";
        return
      }

      // const response = await fetch('http://localhost:8000/send-message', {
      //   method: 'POST',
      //   body: "message=" + encodeURI(this.message) + "&number=" + this.$store.state.currentChat.number,
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded'
      //   }
      // })
      // let decoded = this.$jwt.decode(this.login.token)
      // console.log(decoded.data.id)
      let response = await plataformaService.insertMessage({message: this.message, send_to: this.currentChat.number, send_from: this.phoneList.current.number, id_tecnico: this.login.id_tecnico, is_group: this.currentChat.is_group })
      const messageId = response.data.id;
      let messageData = {
        id: messageId,
        body: this.message,
        send_from: this.phoneList.current.number,
        send_to: this.currentChat.number,
        meta: this.phoneList.current.meta,
        message_date: date.getFullYear() + '-' + (date.getMonth()+1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0') + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
      }
      if (!this.$socket.connected) {
        await this.$socket.connect()
      }
      this.$socket.emit('send_message', messageData)
      this.setToken(response.data.token)
      this.UPDATE_CHAT({...messageData, deleted: 0, add: true})
      this.message = "";
      this.scrollToEnd()
    },
    async saveAttach() {
      if (this.idEncargo.length === 0) {
        return
      }
      if (!(/^\d+$/.test(this.idEncargo))) {
        return
      }
      let ids = this.sendSelected.join(',')
      let response = await plataformaService.saveAttach(ids, this.idEncargo)
      if (response.status === 200) {
        this.idEncargo = null
        this.sendSelected = []
        this.showSelect = !this.showSelect
      }
    },
    showAllMessages() {
      this.getMessagesFromNumber( { client: this.currentChat.number, whatsapp: this.phoneList.current.number, isGroup: this.currentChat.is_group, todos: 1 })
    },
    async updateChats(payload) {
      this.clientUpdate(payload)
      if (typeof payload.add === 'undefined' && typeof payload.deleted === 'undefined') {
        if ((this.currentChat.number === Number(payload.send_from)) && (phoneList.state.current.number === Number(payload.send_to))) {
          await plataformaService.setRead( this.currentChat.number, phoneList.state.current.number, this.currentChat.is_group)
        }
      }
      //TODO MEJOR METER ESTO EN CLIENT UPDATE, PERO HABRÍA QUE METER OTRA CONDICIÓN EN EL ELSE
      let userData = this.chats.lists.filter(message => {
        return message.number === Number(payload.send_from)
      })
      if (!userData.length) {
        await this.getChatList(this.phoneList.current.number)
      }
    },
    updateNotRead() {
      this.getChatList(this.phoneList.current.number)
    },
  },
  mounted() {
    //this.$socket.on('client_update', payload => this.$store.dispatch('clientUpdate', payload))
    //this.$socket.on('client_update', payload => this.clientUpdate(payload))
    this.$socket.on('client_update', payload => this.updateChats(payload))
    this.$socket.on('reset_unread', payload => this.updateChats(payload))
    this.$socket.on('mark_unread', payload => this.updateNotRead(payload))
    this.$socket.on('mark_deleted', payload => this.updateChats(payload))
  },
}
</script>

<style scoped>
  .first__time_chat {
    padding: 8px;
    text-align: center;
    min-height: 50px;
    background-color: #fdf3c5;
    border-radius: 10px;
    color: #525252;
  }

  .no__chat_selected {
    flex: 0.65;
    margin: auto;
    text-align: center;
  }

  .no__chat_selected_text {
    text-align: center;
    margin-top: 10px;
  }

  .no__chat_selected_text > h2 {
    font-weight: 200 !important;
    font-size: 28px !important;
    color: #525252 !important;
    margin-bottom: 10px;
  }

  .no__chat_selected_text > span {
    color: #00000073;
  }

  .no__chat_selected_img {
    width: 40%;
    border-radius: 50%;
  }

  .not__registered {
    text-align: center;
    font-weight: bold;
    color: red;
    font-size: 1.4em;
    border: solid 2px;
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
  }

  .chat {
    flex: 0.65;
    display: flex;
    flex-direction: column;
  }

  .chat__header {
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgrey;
  }

  .chat__header_info {
    flex: 1;
    padding-left: 20px;
  }

  .chat__header_info > h3 {
    margin-bottom: 3px;
    font-weight: bold;
  }

  .chat__header_info > p {
    color: gray;
  }

  .chat__header_right {
    display: flex;
    justify-content: center;
    min-width: 100px;
  }

  .chat__body {
    flex: 1;
    background-image: url("https://github-production-user-asset-6210df.s3.amazonaws.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png");
    background-position: center;
    background-repeat: repeat;
    padding: 30px;
    overflow: scroll;
  }

  .chat__pre {
    white-space: pre;
  }
  .chat__image img {
    width: 200px;
  }

  .chat__row {
    display: flex;
    align-items: center;
  }

  .chat__select {
    margin-right: 20px;
    position: relative;
    top: -15px;
  }

  .chat__message {
    position: relative;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    width: fit-content;
    margin-bottom: 30px;
  }

  .chat__sender:after {
    content: "";
    position: absolute;
    margin-top: -6px;
    margin-left: -5px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #dcf8c6 !important;
    transform: rotate(-42deg);
    right: -10px;
    top: 4px;
  }

  .chat__reciever:after {
    content: "";
    position: absolute;
    margin-top: -6px;
    margin-left: -5px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ffffff !important;
    transform: rotate(42deg);
    left: -6px;
    top: 4px;
  }

  .chat__sender {
    margin-left: auto;
    background-color: #dcf8c6;
    white-space: pre-wrap;
    display: flex;
  }

  .chat__reciever {
    display: flex;
  }

  /*Añadido otra clase porque firefox no soporta :has*/
  .chat__row.chat__center {
    display: flex;
    justify-content: center;
  }

  .chat__showAllMessages {
    background-color: #007a8b;
    border-radius: 0.7em;
    font-size: 0.7em;
    cursor: pointer;
    color: white;
    font-weight: bold;
    margin-bottom: 14px;
    display: inline-block;
    padding: 4px 10px;
    text-transform: uppercase;
  }

  .chat__message:hover .hide  {
    display: inline-block;
  }

  .chat__timestamp {
    margin-left: 10px;
    font-size: xx-small;
  }

  .chat__replied {
    display: block;
    border-left: 4px solid green;
    background-color: #ddd;
    padding: 8px;
    margin-bottom: 4px;
    border-radius: 4px;
    font-size: 0.8em;
  }

  .chat__footer, .chat__messages_save {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 62px;
    border-top: 1px solid lightgrey;
  }

  .chat__footer {
    align-items: flex-end;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .chat__messages_save {
    margin-left: 20px;
  }

  .chat__messages_save .clear__selected {
    display: inline-block;
    padding-right: 10px;
    cursor: pointer;
  }

  .chat__messages_save .clear__selected:hover {
    font-weight: bold;
  }

  .chat__messages_save > div > input {
    flex: 1;
    border-radius: 30px;
    padding: 10px;
    border: none;
  }

  .chat__footer > div.messages {
    flex: 1;
    display: flex;
    align-items: flex-end;
  }

  .chat__footer > div.messages > div {
    flex: 1;
    border-radius: 10px;
    padding: 10px;
    border: solid 1px #c6c6c6;
    max-height: 96px;
    z-index: 2;
  }
  .chat__footer > div.messages > div.placeholder {
    border-radius: 0;
    border: none;
    z-index: 1;
    position: absolute;
    color: #aaaaaa;
  }

  .chat__download {
    cursor: pointer;
    font-size: 0.8em;
  }

  [contenteditable] {
    outline: 0 solid transparent;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }

  [contenteditable]::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .chat__message__group {
    display: flex;
    white-space: break-spaces;
  }

  .hide {
    display: none;
  }

  .mouse_option button {
    margin-top: -8px;
    font-size: 22px;
    margin-left: 4px;
  }
</style>